<template>
  <v-container fill-height class="d-flex align-start">
    <v-row>
      <v-col class="d-flex align-center ma-0 pa-0">
        <p class="mt-1 mr-2 section-title mb-1">Error View Message</p>
        <v-btn
          icon
          color="caribbeanDark"
          @click="copyToClipboard('Error View Message')"
        >
          <v-icon>mdi-code-tags</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- Message Component -->
    <ErrorViewMessage type="404" />
    <!-- Message Component -->
  </v-container>
</template>

<script>
import ErrorViewMessage from '@/components/shared/customComponents/errorView/error-view-message.vue';

export default {
  name: 'ErrorViewWiki',
  components: {
    ErrorViewMessage,
  },

  methods: {
    triggerToast(name) {
      const toastParams = {
        position: { top: true, right: true },
        timeout: 3000,
        messages: { success: `${name} copied to clipboard` },
        type: 'success',
      };

      this.$store.dispatch(
        'toastNotification/setupToastNotification',
        toastParams
      );
      this.$store.dispatch('toastNotification/showToastNotification', true);
    },

    copyToClipboard(name) {
      let component = `<ErrorViewMessage type="404" />`;
      navigator.clipboard.writeText(component);
      this.triggerToast(name);
    },
  },
};
</script>

<style></style>
